<template>
 <div class="row h-100  justify-content-center">

    <div class="col-lg-10 col-xl-10 justify-content-start">
      <div class="d-flex flex-column px-7 mt-10">
        <div class="row align-items-center">
          <div class="col-12 d-flex justify-content-start">
            <div class="d-flex flex-column">
                <h2 class="card-label h1 font-weight-bolder"> {{isBandoZac ? "Format di innovazione" : $t("DRIVERS.CHALLENGE")}}</h2>
                <h5>{{$t("DRIVERS.TUTTITEMI")}}</h5>
            </div>
          </div>
        </div>
         <!--::search-->
        <div class="row my-5">
          <div class="col-xl-12">
            <form method="get" class="quick-search-form">
              <div class="input-group bg-tranparent border-0">
                <div class="input-group-prepend bg-transparent">
                  <button
                    class="input-group-text line-height-0 py-0 bg-transparent border-0"
                    v-on:click="getSearchData($event)"
                  >
                    <img
                      src="/assets/media/bipart/ico-search.png"
                      alt=""
                      class="w-100"
                    />
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control py-10 h4 bg-transparent border-0 text-dark-50"
                  placeholder="Search..."
                  v-model="keywords"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text h3 px-10 text-primary font-weight-bold bg-transparent border-0"
                  >
                    {{ driversCount }}
<!--                    <span class="text-muted h6 ml-4">{{$t("DRIVERS.CLUSTER")}}</span>-->
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!--::Module Filter -->

        <!--begin::Elenco processi row-1-->
        <div class="row gutter-b">
          <div
            id="my-cards"
            class="col-12 col-md-6 col-lg-6 col-xl-6"
            v-for="item in drivers"
            :key="item.id"
          >
            <DriverCard :driver="item"></DriverCard>
          </div>
        </div>

        <!--begin::Pagination-->
        <!-- <Pagination
          v-if="pageCount > 1"
          :current-page="currentPage"
          :page-count="pageCount"
          :item-count="processCount"
          :per-page="$options.static.visibleItemsPerPageCount"
          @firstPage="pageChangeHandle('first')"
          @nextPage="pageChangeHandle('next')"
          @previousPage="pageChangeHandle('previous')"
          @lastPage="pageChangeHandle('last')"
          @loadPage="pageChangeHandle"
        ></Pagination> -->
        <!--end::Pagination -->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
//import Pagination from "@/view/layout/common/Pagination";
import DriverCard from "@/view/pages/drivers/partials/DriversCard";
//import OrganizationMenu from "@/view/layout/common/OrganizationMenu.vue";

export default {
  name: "DriverList",
  components: {
    //Pagination,
    DriverCard,
    //OrganizationMenu
  },
  computed: {
    isBandoZac() {
      return window.location.host.includes('bandozac.bipart.it');
    },
  },
  static: {
    visibleItemsPerPageCount: 4
  },
  data() {
    return {
      drivers: [],
      driversCount: null,
      currentPage: 1,
      pageCount: 0,
      keywords: ""
    };
  },
  mounted() {    
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.isBandoZac ? "Format di innovazione" : this.$t("DRIVERS.CHALLENGE") }]);
    this.getData();
  },
  created() {},
  methods: {
    getSearchData(event) {
      event.preventDefault();
      this.getData();
    },
    getData() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {

        let qs = "";

        if (this.keywords) qs += "&name.contains=" + this.keywords;

        var endpoint = this.generateUrl("agendas-expanded", true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint + "?enabled.equal=true" + qs)
          .then(res => {            
            this.drivers = res.data;
            this.driversCount = res.data.length;
            this.pageCount = Math.ceil(
              res.data.length / this.$options.static.visibleItemsPerPageCount
            );
            this.$isLoading(false);
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },
    async pageChangeHandle(value) {
      switch (value) {
        case "next":
          this.currentPage += 1;
          break;
        case "previous":
          this.currentPage -= 1;
          break;
        case "first":
          this.currentPage = 1;
          break;
        case "last":
          this.currentPage = this.pageCount;
          break;
        default:
          this.currentPage = value;
      }
      await this.getData();
    }
  }
};
</script>

<style scoped></style>
