<template>
  <div>
    <router-link
      :to="{ name: 'driverDetail', params: { id: driver.id, driver_name: driver.name} }"
    >
    <div class="card card-custom mb-3"
    v-bind:class="{ '': driver.enabled }">
      <div class="card-body p-5">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center mr-2">
            <div class="symbol symbol-150 symbol-light flex-shrink-0">
              <div class="symbol-label">
                <img
                v-if="driver.icon1"
                :src="driver.icon1.uri" alt="" class="w-100 rounded"
                />
              </div>
            </div>
            <div class="ml-4">
             
                <span
                  class="h3 text-dark font-weight-bolder text-hover-primary font-size-normal"
                   v-bind:class="{ '': driver.enabled }">
                   {{ driver.name }}
                </span>

                <div class="text-muted my-2" v-html="getDescription"></div>
                     
                <div class="font-size-normal font-weight-bold text-primary">{{ this.driver.challenges.length }} {{ isBandoZac ? "Format di innovazione" : $t("DRIVERS.CHALLENGE") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "DriversCard",
  props: ["driver"],
  computed: {
    getDescription() {
      const description = this.$props.driver.description || "";
      return description.length > 150 ? description.slice(0, 150) + '...' : description;
    },
    isBandoZac() {
      return window.location.host.includes('bandozac.bipart.it');
    }
  },
};
</script>

<style scoped></style>
